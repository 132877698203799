import './App.css';

function App() {
  return (
    <div className="App" >
      <title> Abhay Krishnan M N</title>
      <header>
      <div className = "Title"><h1>Abhay Krishnan M N</h1>
      </div>
      </header>
      <body>
      <div className = "Body">                  
        <div  className ="Education">
          <button id="myBtn" className = "show">
            <h1>Education</h1>
          </button>
          <div id="myDropdown" className="hide">
              <h3>{'>'}ADI SHANKARA INSTITUTE OF ENGINEERING AND TECHNOLOGY</h3>
              {"\n"}2019 – 2023 Kalady, India  
              <br/>B.Tech Major in COMPUTER SCIENCE ENGINEERING and Minor in ROBOTICS and AUTOMATION
              <h3>{'>'}NALANDA PUBLIC SCHOOL</h3>
                <br></br>2016 - 2017 Kochi, India 10th
              <h3>{'>'}NALANDA PUBLIC SCHOOL</h3>
                <br></br>2018 - 2019 Kochi, India 12th
          </div>
        </div>
        <div className ="Certificates" >
          <button id="myBtn" className = "show">
          <h1>Certificates</h1>
          </button>
          <div id="myDropdown" className="hide">
              <h3>{'>'}Digital Marketing</h3>
              <h3>{'>'}Java</h3>
              <h3>{'>'}Python</h3>
              <h3>{'>'}C++</h3>
          </div>
        </div>
        < div className ="Internships">
          <button id="myBtn" className = "show">
              <h1><a href="https://www.linkedin.com/in/abhay-krishnan-m-n-3797b8161">
                Internships
                </a>
                </h1>
           </button>
          <div id="myDropdown" className="hide">
            <h3>{'>'}Hamari Pahchan NGO
              Digital marketing Intern
              06/2020 – 07/2020</h3>
            </div>
        </div>
        <div className ="Skills">
          <button id="myBtn" className = "show">
            <h1>Skills</h1>
          </button>
            <div id="myDropdown" className="hide">
              <h3>{'>'}HTML and CSS</h3>
              <br/>Understanding HTML syntax and fundamentals,
                working with forms for user input, incorporating
                multimedia elements, creating hyperlinks and
                navigation menus, utilizing semantic HTML for
                accessibility and search engine optimization, and
                integrating HTML with CSS for custom styling.

              <h3>{'>'}Python</h3>
              <br/>Understanding data structures like lists, tuples,
                dictionaries, and sets, gaining proficiency in file
                handling operations, grasping the principles of
                Object-Oriented Programming (OOP),
                familiarizing oneself with popular libraries and
                modules for various tasks such as data analysis
                and machine learning, and acquiring knowledge
                of web development frameworks like Django and
                Flask.

               <h3>{'>'}C</h3>
               <br/>Understanding the syntax and fundamentals of C,
                memory management with pointers, working with
                arrays and strings, modular programming, error
                handling and debugging techniques, and
                familiarity with standard libraries and functions.

               <h3>{'>'}Netwoking</h3>
               <br/>Understanding network architectures and
                protocols, network addressing and subnetting,
                network infrastructure components, network
                security principles and technologies, network
                troubleshooting and diagnostics, and familiarity
                with emerging networking technologies.

               <h3>{'>'}Hardware Assembly</h3>
               <br/>Understanding computer hardware components,
                hardware compatibility and standards, safe
                handling and electrostatic discharge procedures,
                hardware installation techniques, cable
                management, BIOS/UEFI configuration, hardware
                troubleshooting, and familiarity with hardware
                upgrades and expansions.

               <h3>{'>'}JavaScript and Node.js</h3>
               <br/>Understanding JavaScript syntax and
                fundamentals, manipulating the Document
                Object Model (DOM) for interactive web page
                development, working with asynchronous
                programming for handling data requests and
                updates, utilizing arrays and objects for efficient
                data manipulation, implementing features for
                working with dates, error handling, and regular
                expressions, leveraging JavaScript frameworks
                and libraries for enhanced productivity, and
                having strong problem-solving and debugging
                skills. Node.js modules, utilizing third-party
                packages from the npm ecosystem, knowledge of
                Express.js for web application development,
                proficiency in asynchronous control flow
                mechanisms, database integration, and
                understanding concepts like streams and event
                emitters.

               <h3>{'>'}Java</h3>
               <br/>Understanding of Java syntax and fundamentals,
                proficiency in object-oriented programming (OOP)
                concepts, memory management, familiarity with
                the Java standard library, expertise in exception
                handling, knowledge of multithreading and
                concurrent programming, and proficiency in Java
                frameworks and technologies.

               <h3>{'>'}C++</h3>
               <br/>Understanding the syntax and fundamentals of
                C++, memory management with pointers and
                smart pointers, object-oriented programming
                concepts, such as classes and inheritance,
                utilizing templates and the standard template
                library (STL), error handling and exception
                handling, and performing input/output
                operations.

               <h3>{'>'}Robotics</h3>
               <br/>Understanding principles of robotics, knowledge
                of robot hardware components, proficiency in
                robot programming and control, understanding
                robot kinematics and dynamics, familiarity with
                robot perception and machine learning,
                knowledge of robot safety and ethical
                considerations, and familiarity with emerging
                technologies.
            </div>
        </div>
        <div className ="Projects">
          <button id="myBtn" className = "show">
            <h1><a href="https://github.com/abhaykrishnanmn">
              Projects
              </a>
            </h1>
            </button>
            <div id="myDropdown" className="hide">
              <h3>{'>'}<a href="https://github.com/abhaykrishnanmn/crs">
                Collage Placement Portal
              </a>
              </h3>
              <br/>
                The college placement portal is a web application developed in the Django framework, offering a centralized
                platform for on-campus drives while incorporating web scraping capabilities for off-campus placements. It
                enables students to create profiles, upload resumes, and browse on-campus job opportunities, while also
                web scraped gathering job listings from external sources for off-campus placements. Recruiters can register,
                post job listings, and manage the hiring process. The use of Django ensures a robust and secure platform,
                providing an efficient and comprehensive solution for connecting students and recruiters, ultimately
                enhancing the placement experience.


              <h3>{'>'}<a href="https://github.com/abhaykrishnanmn/Server-Authentication">
                Server Authentication
                </a>
                </h3>
                <br/>
                The Server Authentication project focuses on developing a secure and reliable authentication system for
                servers. It involves implementing various authentication protocols and techniques to verify client identity and
                establish secure communication channels. This includes secure login procedures, encryption algorithms,
                multi-factor authentication, user management, and integration of industry-standard or custom authentication
                mechanisms. The project aims to create a robust system that protects server resources, ensures data
                confidentiality, and establishes trust between the server and clients.


               <h3>{'>'}<a href="https://github.com/abhaykrishnanmn/Loan_App_Project">
                Loan Eligibility Checker
                </a>
                </h3>
                <br/>
                The Loan Eligibility Checker project combines machine learning and Flask to develop a web application that
                assesses loan eligibility for users. The project involves training a machine learning model using historical loan
                data to learn patterns and make predictions on loan approvals. The Flask framework is used to build a web
                interface that allows users to input their relevant information, such as income, credit score, and loan amount.
                The application then utilizes the trained machine learning model to evaluate the user's eligibility for a loan.
                The output is displayed to the user, indicating whether they are likely to be approved or not. The project aims
                to provide a user-friendly and efficient tool for individuals to determine their loan eligibility based on data driven predictions, enhancing the decision-making process for both borrowers and lenders.
            </div>
        </div>
        <div className ="Languages">
         <button id="myBtn" className = "show">
          <h1>Languages</h1>
          </button>
          <div id="myDropdown" className="hide">
              <h3>{'>'}English</h3>
              <h3>{'>'}Malayalam</h3>
              <h3>{'>'}Hindi</h3>
              <h3>{'>'}Tamil</h3>
          </div>
        </div>
      </div>
      </body>      
    </div>
  );

}



export default App;
